<template>
    <a-page-header :ghost="false" :title="title" @back="() => $router.go(-1)" class="pageHeader">
        <template #extra>
            <div style="marginLeft:auto">
                <a-button type="primary" style="width:150px" @click="handleSubmit">提交</a-button>
            </div>
        </template>
    </a-page-header>
    <div class="tableBox">
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
            <a-form-item label="文章标题：" name="title">
                <a-input v-model:value="form.title" placeholder="请输入文章标题" />
            </a-form-item>
            <a-form-item label="文章标签：" name="name">
                <a-tag v-for="(tagItem, index) in form.tag" :key="tagItem" class="tagSty" closable @close="handleLabelClose(tagItem)">{{ tagItem }}</a-tag>
                <a-input v-if="inputVisible" ref="inputRef" type="text" :style="{ width: '150px',padding: '5px 20px' }" v-model:value="inputValue" @blur="handleLabelConfirm" @keyup.enter="handleLabelConfirm" />
                <a-tag v-else @click="showLabelInput" color="warning" class="tagSty">
                    <plus-outlined />新建标签
                </a-tag>
            </a-form-item>
            <a-form-item label="文章封面（尺寸400*300）：" name="cover">
                <UploadImage file_type="article" :img="form.cover" width="400px" height="300px" @uploadImage="uploadImage">
                    <a-image v-if="form.cover" :width="199" :height="150" :preview="false" :src="domain+form.cover" :fallback="domain+'/default.png'" style="border-radius:4px" />
                </UploadImage>
            </a-form-item>
            <a-row>
                <div class="content"><span class="text">*</span>文章内容：</div>
                <Editor :value="form.content" @handEditor="handEditor"></Editor>
            </a-row>
        </a-form>
    </div>
</template>
<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, toRefs, getCurrentInstance, onMounted, nextTick } from 'vue';
import UploadImage from '@/components/UploadImage';
import Editor from '@/components/Editor';
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
    name: 'BannerManagementEdit',
    components: {
        LoadingOutlined,
        PlusOutlined,
        Editor,
        UploadImage
    },

    setup(props, ctx) {
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const router = useRouter();
        const formRef = ref();
        const inputRef = ref();
        onMounted(() => {
            if (route.query.id) ReadArticle(route.query.id);
        })

        const initData = reactive({
            form: {
                content: '',
                tag: []
            },
            rules: {
                title: [{
                    required: true,
                    message: '请输入文章名称',
                }],
                cover: [{
                    required: true,
                    message: '请上传文章封面',
                    trigger: ['change', 'blur']
                }],
            },
            
            domain: proxy.domain,
            inputValue: '',

            inputVisible: false,
        });

        const ReadArticle = (id) => {
            proxy.http.post('/admin.article/read', { id: id }).then(response => {
                initData.form = response;
            })
        }

        const showLabelInput = () => {
            initData.inputVisible = true;
            nextTick(() => {
                inputRef.value.focus();
            });
        };
        //删除便签
        const handleLabelClose = (removedTag) => {
            initData.form.tag = initData.form.tag.filter(tag => tag !== removedTag);
        };
        //保存标签
        const handleLabelConfirm = () => {
            const inputValue = initData.inputValue;
            const tags = initData.form.tag;
            if (inputValue && tags.indexOf(inputValue) === -1) {
                initData.form.tag = [...tags, inputValue];
            }
            Object.assign(initData, { tags, inputVisible: false, inputValue: '' });
        };

        // 保存
        const handleSubmit = () => {
            formRef.value.validate().then(() => {
                if (!initData.form.content) return proxy.message.error('请填写文章内容！');
                proxy.http.post('/admin.article/save', initData.form).then(() => {
                    proxy.message.success('提交成功');
                    router.go(-1)
                })
            }).catch(() => { });
        }

        //上传图片
        const uploadImage = (event) => {
            formRef.value.clearValidate()
            initData.form.cover = event;
        };
        //获取文章内容
        const handEditor = (event) => {
            initData.form.content = event;
        }

        return {
            ...toRefs(initData),
            title: route.query.id ? '编辑文章' : '添加文章',
            formRef,
            inputRef,
            uploadImage,
            handEditor,//富文本内容
            handleLabelConfirm,//新建标签
            handleLabelClose,//关闭标签
            showLabelInput,//显示标签
            ReadArticle,//文章详情
            handleSubmit,//提交
        };
    },
});
</script>
<style lang="less" scoped>
.tableBox {
    padding: 20px;
    .tagSty {
        padding: 5px 20px;
    }
    .content {
        padding-bottom: 8px;
        .text {
            margin-right: 4px;
            color: #ff4d4f;
        }
    }
}
.pageHeader {
    background-color: #f0f2f5;
    padding: 0 0 15px;
    :deep(.ant-page-header-heading-title) {
        font-weight: normal !important;
    }
}
</style>

